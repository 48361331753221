import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router';

import { Application } from '@src/models/application';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { applicationsQueryKeys } from '../applicationsQueryKeys';

export const useApplicationPatchMutation = () => {
  // Router hooks
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  // React Query
  const queryClient = useQueryClient();

  const { data, ...queryResult } = useMutation<
    AxiosResponse<Application>,
    AxiosError,
    {
      appId: string;
      appName: string;
    }
  >({
    mutationFn: ({ appId, appName }) => {
      return makeRequest('PATCH', `/orgs/${orgId}/apps/${appId}`, { name: appName });
    },
    onSuccess: ({ data: app }) => {
      queryClient.invalidateQueries({
        queryKey: applicationsQueryKeys.detail(orgId, app.id),
      });

      queryClient.invalidateQueries({
        queryKey: applicationsQueryKeys.list(orgId),
      });
    },
  });

  return { ...queryResult, data: data?.data };
};
