import { Placement } from '@floating-ui/react';
import { get } from 'lodash';
import { rem } from 'polished';
import React, {
  Dispatch,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { ErrorOption, useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components';

import DropdownSVG from '@src/components/shared/svg/DropdownSVG';
import { usePrevious } from '@src/hooks/usePrevious';
import { units } from '@src/styles/variables';

import { Button, ButtonSize, ButtonVariant } from '../../../base/Button/Button';
import { inputHeight } from '../../../base/Input/Input';
import { DisplayFieldErrors } from '../../../base/Input/InputWrapper';
import { WalLabel } from '../../../base/Label';
import { PanelSize } from '../../../base/Panel/Panel';
import { Text } from '../../../base/Text/Text';
import { FixedMenuRow, MenuItem, WalMenu } from '../Menu';

export interface DropdownItem<T> extends MenuItem<T> {
  id: string;
  notSelectable?: boolean;
  previousValue?: string;
}

export interface WalDropdownMenuProps<T> {
  /**
   * List of menu items.
   * Note: For items related to org roles pass options from src/hooks/useRoleDropdownOptions.tsx
   * */
  items: DropdownItem<T>[];
  /** If name is passeed, react-hook form will be used */
  name?: string;
  /** Only applies if name is used */
  required?: boolean;
  /** Default selected value */
  defaultValue?: string;
  /** Default text if no option is selected */
  defaultText?: string;
  /** Displays default text even if option is selected */
  alwaysShowDefaultText?: boolean;
  /** Displays react component instead of Default text and it's always pinned at the top regardless of menu option selected */
  defaultAlwaysShownComponent?: ReactNode;
  /** Click handler called when an item is selected */
  onItemClick?: (id: string, item: DropdownItem<T>, e: MouseEvent | KeyboardEvent) => void;
  /** The size of the dropdown toggle */
  buttonSize?: ButtonSize;
  /** if the toggle should take the 100% width of the parent */
  fullWidth?: boolean;
  /** if the dropdown should not have a border of the given side */
  noBorder?: 'right' | 'left';
  /** dropdown variant style */
  buttonVariant?: ButtonVariant;
  /** component that is fixed to the top of the dropdown menu */
  fixedTopRowComponent?: ReactNode;
  /** component that is fixed to the bottom of the dropdown menu */
  fixedBottomRow?: FixedMenuRow | FixedMenuRow[];
  /** max height for the menu if it overflows the scrolling will be activated */
  maxHeight?: number;
  width?: number;
  /** If the dropdown is disabled */
  disabled?: boolean;
  /** description of the field */
  description?: string;
  /** Optional classname for styled components */
  className?: string;
  /** size of menu component */
  menuSize?: PanelSize;
  /** useState for open state */
  openState?: [boolean, Dispatch<SetStateAction<boolean>>];
  /** label for the dropdown */
  label?: string;
  hideLabel?: boolean;
  id?: string;
  disableCloseOnClick?: boolean;
  /** a function that is triggered when the menu is closed */
  onMenuClosed?: () => void;
  /** Dropdown max width in pixels */
  maxWidth?: number;
  /** dataTestId */
  dataTestId?: string;
  overflowScrollable?: boolean;
  /** Displays text when there are no items */
  noItemsText?: string;
  allowUpdatesToDefaultValue?: boolean;
  /** if the panel should be opened on top of the toggle */
  openUpwards?: boolean;
  /** If content should remain when items === 0 */
  alwaysMaintainPanelContent?: boolean;
  toggleAriaLabel?: string;
  prioritizeLabelAsDisplayedValue?: boolean;
  /** Enable this to hide error message helper text */
  hideError?: boolean;
  /* where the panel should be shown */
  placement?: Placement;
  /* disables floating ui auto shifting of the floating element */
  disableFloatingUiShift?: boolean;
}
const Wrapper = styled.div<{ fullWidth?: boolean; width?: number; $hideLabel?: boolean }>`
  position: relative;
  ${({ $hideLabel }) =>
    !$hideLabel &&
    css`
      padding-top: ${units.margin.lg};
    `}
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}

  ${({ width }) =>
    width &&
    css`
      width: ${rem(width)};
    `}
`;

const DropdownLabel = styled(WalLabel)`
  position: absolute;
  top: ${rem(4)};
  font-size: ${units.fontSize.sm};
`;

export const DropdownButton = styled(Button)<{
  $maxWidth?: number;
  fullWidth?: boolean;
  size?: ButtonSize;
}>`
  ${({ $maxWidth }) =>
    $maxWidth &&
    css`
      max-width: ${rem($maxWidth)};
    `};
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
  position: relative;
  justify-content: flex-start;
  ${({ size }) =>
    size
      ? css`
          min-height: ${inputHeight[size]};
        `
      : css`
          min-height: ${inputHeight.medium};
        `};
  ${({ variant }) => {
    return variant !== 'primary'
      ? css`
          color: ${({ theme }) => theme.color.text};
          .dropdown-arrow path {
            fill: ${({ theme }) => theme.color.text};
          }

          &:hover .dropdown-arrow path {
            fill: ${({ theme }) => theme.color.mainBrighter};
          }
        `
      : css`
          path {
            fill: white;
          }
        `;
  }}

  ${({ disabled }) => {
    if (disabled) {
      return css`
        path {
          fill: ${({ theme }) => theme.color.textTranslucent};
        }
      `;
    }
  }}

  ${({ size }) => {
    let paddingRight;
    switch (size) {
      case 'large':
        paddingRight = rem(36);
        break;
      case 'small':
        paddingRight = rem(28);
        break;
      case 'medium':
      default:
        paddingRight = rem(32);
    }
    return css`
      padding-right: ${paddingRight};
    `;
  }};
`;

const SelectElement = styled.select`
  visibility: hidden;
  display: none;
`;

interface SelectElementProps {
  name: string;
  items: DropdownItem<any>[];
  defaultValue?: string;
  required?: boolean;
  clickedValue: string;
  id: string;
}

const FormSelect = ({
  name,
  items,
  defaultValue,
  clickedValue,
  required,
  id,
}: SelectElementProps) => {
  const { register, setValue, watch, trigger } = useFormContext();

  const selectValue = watch(name);

  useEffect(() => {
    if (selectValue) trigger(name);
  }, [selectValue, trigger, name]);

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue);
    }
  }, [setValue, name, defaultValue]);

  useEffect(() => {
    if (clickedValue) {
      setValue(name, clickedValue, { shouldDirty: true });
    }
  }, [clickedValue, setValue, name]);

  return (
    <SelectElement {...register(name, { required: required && 'This field is required' })} id={id}>
      <option />
      {items
        .filter((i) => !i.hideFromList && !i.separatorItem)
        .map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <option value={item.value} key={index}>
            {item.label}
          </option>
        ))}
    </SelectElement>
  );
};

/**
 * Returns a dropdown button component.
 */
export const WalDropdownMenu = <T extends unknown>({
  buttonSize,
  fullWidth,
  items = [],
  defaultValue,
  onItemClick,
  noBorder,
  buttonVariant,
  fixedTopRowComponent,
  fixedBottomRow,
  maxHeight,
  disabled,
  menuSize,
  className,
  openState,
  label,
  hideLabel,
  id,
  disableCloseOnClick,
  onMenuClosed,
  maxWidth,
  name,
  defaultText,
  dataTestId,
  overflowScrollable,
  alwaysShowDefaultText,
  defaultAlwaysShownComponent,
  width,
  required,
  noItemsText,
  allowUpdatesToDefaultValue = true,
  alwaysMaintainPanelContent,
  toggleAriaLabel,
  prioritizeLabelAsDisplayedValue,
  placement,
  disableFloatingUiShift,
  hideError = false,
  description,
}: WalDropdownMenuProps<T>) => {
  const [selectedItem, setSelectedItem] = useState<DropdownItem<T> | string>();
  const [clickedValue, setClickedValue] = useState<string>('');
  const previousClickedValue = usePrevious(clickedValue);
  const {
    watch,
    formState: { errors },
  } = useFormContext();

  const fieldError = name && (get(errors, name) as ErrorOption);

  /**
   * Default value will always override `clickedValue` if it changes.
   */
  useEffect(() => {
    if (name) {
      const formValue = watch(name);
      setSelectedItem(items.find((item) => item.id === formValue) || formValue);
      // TODO: Only find item from form WAL-3946.
    } else {
      setSelectedItem((prevState) => {
        // only set the selectedItem if it doesn't exist already
        if ((prevState && !allowUpdatesToDefaultValue) || !defaultValue) {
          return prevState;
        }
        return typeof defaultValue === 'string'
          ? items.find((item) => item.id === defaultValue) || defaultValue
          : defaultValue;
      });
    }
  }, [items, allowUpdatesToDefaultValue, watch, name, defaultValue]);

  useEffect(() => {
    // `items` may change a lot. So only set the item if clickedValue has changed.
    if (clickedValue && previousClickedValue !== clickedValue) {
      setSelectedItem(items.find((item) => item.id === clickedValue));
    }
  }, [clickedValue, items, previousClickedValue]);

  /**
   * Updates the item click and updates the selected state.
   *
   * @param item the clicked item,
   */
  const onClick = (item: DropdownItem<T>, e: MouseEvent | KeyboardEvent) => {
    if (!item.notSelectable) {
      setClickedValue(item.id);
    }
    if (onItemClick) {
      onItemClick(item.id, item, e);
    }
  };

  return (
    <Wrapper
      className={className}
      fullWidth={fullWidth}
      width={width}
      data-testid={dataTestId}
      $hideLabel={!label || hideLabel}>
      {label && !hideLabel && <DropdownLabel htmlFor={name}>{label}</DropdownLabel>}
      <>
        <WalMenu
          onItemClick={onClick}
          onMenuClosed={onMenuClosed}
          disableCloseOnClick={disableCloseOnClick}
          items={items}
          selected={selectedItem}
          fixedBottomRow={fixedBottomRow}
          fixedTopRowComponent={fixedTopRowComponent}
          maxHeight={maxHeight}
          fullWidth={fullWidth}
          disabled={disabled}
          openState={openState}
          maxWidth={maxWidth}
          overflowScrollable={overflowScrollable}
          panelSize={menuSize ? menuSize : 'fitContent'}
          alwaysShowDefaultText={alwaysShowDefaultText}
          noItemsText={noItemsText}
          alwaysMaintainPanelContent={alwaysMaintainPanelContent}
          mode={'dropdown'}
          panel={{ placement }}
          disableFloatingUiShift={disableFloatingUiShift}
          toggle={
            <DropdownButton
              id={id}
              fullWidth={fullWidth}
              noBorder={noBorder}
              variant={buttonVariant || 'secondary'}
              size={buttonSize}
              disabled={disabled}
              $maxWidth={maxWidth}
              ariaLabel={toggleAriaLabel}
              onClick={(e) => e.preventDefault()}>
              {noItemsText && items.length === 0
                ? noItemsText
                : defaultAlwaysShownComponent
                  ? defaultAlwaysShownComponent
                  : defaultText && alwaysShowDefaultText
                    ? defaultText
                    : selectedItem
                      ? typeof selectedItem !== 'string'
                        ? prioritizeLabelAsDisplayedValue
                          ? selectedItem.label || selectedItem?.component
                          : (selectedItem?.component ?? selectedItem.label)
                        : selectedItem
                      : defaultText}
              <DropdownSVG size={buttonSize} />
            </DropdownButton>
          }
        />
        {name && (
          <FormSelect
            required={required}
            name={name}
            id={name}
            items={items}
            defaultValue={defaultValue}
            clickedValue={clickedValue}
          />
        )}
        {description && (
          <Text className={'mt-sm'} color={'textTranslucent'} size={'sm'}>
            {description}
          </Text>
        )}
        {!hideError && fieldError && (
          <DisplayFieldErrors fieldErrors={fieldError} oneField={{ label }} />
        )}
      </>
    </Wrapper>
  );
};

export default WalDropdownMenu;
