import {
  Button,
  EmptyStateCard,
  FilterByOption,
  FilterByOptions,
  FilterConditions,
  GroupByItem,
  Icon,
  MultiFilterTable,
  Tag,
  WalTable,
  WalTableColumn,
} from '@humanitec/ui-components';
import { compact, uniq } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router';
import styled from 'styled-components';

import ConfirmDeleteModal from '@src/components/shared/ConfirmDeleteModal/ConfirmDeleteModal';
import { VersionLink } from '@src/containers/Orgs/Resources/components/ResourceDefinition/components/ResourceDefinitionUsage/components/VersionLink';
import ShowMatchingCriteriaInfo from '@src/containers/Orgs/Resources/components/ResourceDefinition/components/ShowMatchingCriteriaInfo';
import { filterActiveResources } from '@src/containers/Orgs/Resources/components/ResourcesTable/resource-filter-utils';
import useApplicationsQuery from '@src/hooks/react-query/applications/queries/useApplicationsListQuery';
import useEnvironmentTypesQuery from '@src/hooks/react-query/environment-types/queries/useEnvironmentTypesQuery';
import useActiveResourceDeleteMutation from '@src/hooks/react-query/resources/mutations/useActiveResourceDeleteMutation';
import useActiveResourcesQuery from '@src/hooks/react-query/resources/queries/useActiveResourcesQuery';
import useResourceDefinitionByIdQuery from '@src/hooks/react-query/resources/queries/useResourceDefinitionByIdQuery';
import { useResourceDefinitionVersionsQuery } from '@src/hooks/react-query/resources/queries/useResourceDefinitionVersions';
import { useDecision } from '@src/hooks/useDecision';
import { useRBAC } from '@src/hooks/useRBAC';
import i18n from '@src/i18n/i18n';
import { ActiveResource } from '@src/models/resources';
import { units } from '@src/styles/variables';
import { findMostSpecficMatchingCriteria } from '@src/utilities/find-most-specific-matching-criteria/find-most-specific-matching-criteria';
import { generateAppURL } from '@src/utilities/navigation';

import { PinActiveResourceToVersionModal } from './components/PinActiveResourceToVersionModal/PinActiveResourceToVersionModal';
import { PinnedToColumn } from './components/PinnedToColumn/PinnedToColumn';
import { ProvisionedVersionColumn } from './components/ProvisionedVersionColumn';

const ActionsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
`;

const Text = styled.p`
  font-size: ${units.fontSize.base};
  margin-bottom: ${units.margin.lg};
`;

const MatchingColumnContianer = styled.div`
  max-width: 100%;
`;

const DetailsText = styled.div`
  display: flex;
  font-size: ${units.fontSize.sm};
  margin-bottom: ${units.margin.md};
  word-break: break-all;
`;

const DetailsHeading = styled.div`
  color: ${({ theme }) => theme.color.textTranslucent};
  margin-bottom: ${units.margin.sm};
  font-size: ${units.fontSize.sm};
`;

const DeleteActiveResourceTable = styled(WalTable)`
  margin-top: ${units.margin.md};
  margin-bottom: ${units.margin.md};
`;

const externalTranslations = i18n.t('ACCOUNT_SETTINGS').RESOURCES.ACTIVE_RESOURCES;

export const baseActiveResourcesColumns: WalTableColumn<
  ActiveResource,
  'res_id' | 'app_id' | 'env_id' | 'env_type'
>[] = [
  {
    label: externalTranslations.RESOURCE_ID,
    prop: 'res_id',
  },
  {
    label: externalTranslations.APPLICATION_ID,
    prop: 'app_id',
    template: (row) => <p>{row.data.app_id || '-'}</p>,
  },
  {
    label: externalTranslations.ENVIRONMENT_ID,
    prop: 'env_id',
    template: (row) => <p>{row.data.env_id || '-'}</p>,
  },
  {
    label: externalTranslations.ENVIRONMENT_TYPE,
    prop: 'env_type',
    justifyContent: 'flex-start',
    template: (row) => <p>{row.data.env_type || '-'}</p>,
  },
];

const ResourceDefinitionUsage = () => {
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState<boolean>(false);
  const [activeResourceToDelete, setActiveResourceToDelete] = useState<ActiveResource>();
  // i18n
  const { t } = useTranslation();
  const translations = t('ACCOUNT_SETTINGS').RESOURCES.ACTIVE_RESOURCES;
  const uiTranslations = t('UI');

  // react query
  const { data: activeResources } = useActiveResourcesQuery();
  const { data: resourceDefinition } = useResourceDefinitionByIdQuery();
  const { data: resourceDefinitionVersions } = useResourceDefinitionVersionsQuery();
  const {
    mutate: deleteResource,
    isSuccess: isDeletedSuccessfully,
    reset: resetDeleteMutation,
  } = useActiveResourceDeleteMutation();
  const { data: applications = [] } = useApplicationsQuery();
  const { data: envTypes = [] } = useEnvironmentTypesQuery();

  // RBAC
  const canUpdateResourceDefinition = useRBAC('manageResources');

  // state
  const [filterConditions, setFilterConditions] = useState<FilterConditions>();

  // Optimizely
  const [resourceDefinitionVersionDecision] = useDecision('resource-definition-version');
  const [showResourceDefinitionVersionDecision] = useDecision('version-resource-def-usage');

  const envIds = uniq(applications?.flatMap((app) => app.envs).map((env) => env.id));
  const appIds = applications?.map((app) => app.id);
  const resIds = uniq(compact(activeResources?.map((res) => res.res_id)));
  const envTypeIds = envTypes?.map((envType) => envType.id);

  const handleDeleteClick = (resource: ActiveResource) => {
    setOpenDeleteConfirmationModal(true);
    setActiveResourceToDelete(resource);
  };

  const handleConfirmDeleteActiveResource = () => {
    if (activeResourceToDelete) {
      deleteResource({ resource: activeResourceToDelete });
    }
  };

  useEffect(() => {
    if (isDeletedSuccessfully) {
      setOpenDeleteConfirmationModal(false);
      resetDeleteMutation();
    }
  }, [isDeletedSuccessfully, resetDeleteMutation]);

  const filteredActiveResources = useMemo(() => {
    return activeResources ? filterActiveResources(activeResources, filterConditions) : [];
  }, [activeResources, filterConditions]);

  const matchingCriteriaFilterByOptions: FilterByOption[] = [
    {
      label: translations.APPLICATION_ID,
      id: 'app_id',
      value: 'app_id',
      comboSelectOptions: appIds.map((id) => ({ id, label: id, searchString: id, value: id })),
    },
    {
      label: translations.ENVIRONMENT_ID,
      id: 'env_id',
      value: 'env_id',
      comboSelectOptions: envIds.map((id) => ({ id, label: id, searchString: id, value: id })),
    },
    {
      label: translations.RESOURCE_ID,
      id: 'res_id',
      value: 'res_id',
      comboSelectOptions: resIds.map((id) => ({ id, label: id, searchString: id, value: id })),
    },
    {
      label: translations.ENVIRONMENT_TYPE,
      id: 'env_type',
      value: 'env_type',
      comboSelectOptions: envTypeIds.map((environmentType) => ({
        id: environmentType,
        label: environmentType,
        searchString: environmentType,
        value: environmentType,
      })),
    },
    {
      label: translations.RESOURCE_CLASS,
      id: 'class',
      value: 'class',
    },
  ];

  const filterByOptions: FilterByOptions = {
    criteria: {
      title: translations.BY_MATCHING_CRITERIA,
      options: matchingCriteriaFilterByOptions,
    },
  };

  const groupByOptions: GroupByItem<string>[] = [
    { id: 'none', value: 'none', label: translations.NONE },
    { id: 'app_id', value: 'app_id', label: translations.APPLICATION_ID },
    { id: 'env_id', value: 'env_id', label: translations.ENVIRONMENT_ID, parent: 'app_id' },
    { id: 'env_type', value: 'env_type', label: translations.ENVIRONMENT_TYPE },
  ];

  const displayCriteriaText = (
    resource: ActiveResource,
    numberOfShownProps?: number,
    maxWidth?: string | undefined,
    allowOverflow?: boolean | undefined
  ) => {
    const mostSpecificCriteria =
      resourceDefinition?.criteria &&
      findMostSpecficMatchingCriteria(resource, resourceDefinition?.criteria);
    if (mostSpecificCriteria) {
      return (
        <MatchingColumnContianer>
          <ShowMatchingCriteriaInfo
            numberOfShownProps={numberOfShownProps}
            criteria={mostSpecificCriteria}
            maxWidth={maxWidth}
            allowOverflow={allowOverflow}
          />
        </MatchingColumnContianer>
      );
    }
    return <div>-</div>;
  };

  const columns: WalTableColumn<
    ActiveResource,
    | 'res_id'
    | 'app_id'
    | 'env_id'
    | 'env_type'
    | 'criteria'
    | 'actions'
    | 'active-version'
    | 'pinned-to'
  >[] = [
    ...baseActiveResourcesColumns,
    {
      label: translations.MATCHING,
      prop: 'criteria',
      template: (row) => displayCriteriaText(row.data, 1, '400px'),
    },
    {
      label: translations.PROVISIONED_VERSION,
      prop: 'active-version',
      template: (row) => <ProvisionedVersionColumn activeResource={row.data} />,
      hide: !resourceDefinitionVersionDecision.enabled,
    },
    {
      label: translations.PINNED_TO,
      prop: 'pinned-to',
      template: (row) => <PinnedToColumn activeResource={row.data} />,
      hide: !resourceDefinitionVersionDecision.enabled,
      justifyContent: 'flex-start',
    },
    {
      prop: 'actions',
      tabIndex: -1,
      hide: !canUpdateResourceDefinition,
      template: (row) => {
        return (
          <ActionsWrapper>
            {row.data.status === 'deleting' && (
              <Tag className={'mr-md'} text={translations.PENDING_DELETION} variant={'warning'} />
            )}
            {resourceDefinitionVersionDecision.enabled && (
              <PinActiveResourceToVersionModal activeResource={row.data} />
            )}
            <Button
              iconLeft={'delete'}
              size={'small'}
              variant={'secondary'}
              ariaLabel={translations.DELETE_RESOURCE}
              disabled={row.data.status === 'deleting'}
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteClick(row.data);
              }}
            />
          </ActionsWrapper>
        );
      },
    },
  ];

  const renderRowDetails = (activeResource: ActiveResource) => {
    return (
      <>
        <DetailsHeading>{translations.RESOURCE_ID}</DetailsHeading>
        <DetailsText>{activeResource.res_id}</DetailsText>
        <DetailsHeading>{translations.RESOURCE_CLASS}</DetailsHeading>
        <DetailsText>{activeResource.class}</DetailsText>
        {columns
          .filter((column) => column.prop !== 'res_id')
          .map((column) => {
            if (column.prop === 'criteria') {
              return (
                <React.Fragment key={column.prop}>
                  <DetailsHeading>{translations.MATCHING_CRITERIA_USED}</DetailsHeading>
                  <DetailsText>
                    {displayCriteriaText(activeResource, undefined, undefined, true)}
                  </DetailsText>
                </React.Fragment>
              );
            }
            if (
              column.prop &&
              column.prop !== 'actions' &&
              column.prop !== 'active-version' &&
              column.prop !== 'pinned-to'
            ) {
              return (
                <React.Fragment key={column.prop}>
                  <DetailsHeading>{column.label}</DetailsHeading>
                  <DetailsText>
                    {activeResource[column.prop]}{' '}
                    {column.prop === 'app_id' && activeResource.status !== 'deleting' && (
                      <Link
                        to={generateAppURL(activeResource.org_id, activeResource.app_id)}
                        target={'_blank'}
                        rel={'noopener noreferrer'}>
                        <Icon
                          name={'link'}
                          overrideColor={'main-brighter'}
                          marginLeft={'sm'}
                          pointer
                        />{' '}
                      </Link>
                    )}
                    {column.prop === 'env_id' && activeResource.status !== 'deleting' && (
                      <Link
                        to={generateAppURL(
                          activeResource.org_id,
                          activeResource.app_id,
                          activeResource.env_id
                        )}
                        target={'_blank'}
                        rel={'noopener noreferrer'}>
                        <Icon
                          name={'link'}
                          overrideColor={'main-brighter'}
                          marginLeft={'sm'}
                          pointer
                        />{' '}
                      </Link>
                    )}
                  </DetailsText>
                </React.Fragment>
              );
            }
            return <React.Fragment key={column.prop} />;
          })}
        {showResourceDefinitionVersionDecision.enabled && (
          <>
            <DetailsHeading>{translations.RESOURCE_DEF_VERSION}</DetailsHeading>
            <DetailsText>
              {activeResource.status !== 'deleting' && (
                <VersionLink
                  resourceDefinitionVersion={resourceDefinitionVersions?.find(
                    (version) => version.id === activeResource.def_version_id
                  )}
                />
              )}
            </DetailsText>
          </>
        )}
      </>
    );
  };

  const renderDeleteActiveResourceModalContent = () => {
    const delteResourceColumns: WalTableColumn[] = [
      {
        label: translations.APP_ID,
        prop: 'appId',
        template: (row) => <p>{row.data.appId || '-'}</p>,
      },
      {
        label: translations.ENV_NAME,
        prop: 'envId',
        template: (row) => <p>{row.data.envId || '-'}</p>,
        justifyContent: 'flex-start',
      },
    ];

    return (
      <>
        <Trans defaults={translations.CONFIRM_DELETE_ACTIVE_RESOURCE_MESSAGE_1} />
        <DeleteActiveResourceTable
          caption={translations.DELETE_ACTIVE_RESOURCE}
          rows={[
            {
              data: {
                appId: activeResourceToDelete?.app_id,
                envId: activeResourceToDelete?.env_id,
              },
            },
          ]}
          columns={delteResourceColumns}
          disableScrolling
        />
        <Trans defaults={translations.CONFIRM_DELETE_ACTIVE_RESOURCE_MESSAGE_2} />
      </>
    );
  };
  return (
    <>
      <Text>{translations.SUBTEXT}</Text>
      {activeResources && activeResources.length > 0 ? (
        <MultiFilterTable
          tableStyle={'expandable'}
          rows={filteredActiveResources?.map((activeResource) => ({
            data: activeResource,
            expandableContent: renderRowDetails(activeResource),
          }))}
          filterByOptions={filterByOptions}
          onFiltersChange={(conditions?: FilterConditions) => {
            setFilterConditions(conditions);
          }}
          groupByOptions={groupByOptions}
          columns={columns}
          caption={translations.ACTIVE_RESOURCES}
          alignGroupedTableCellByWidthOf={'200px'}
          initialRowsCount={activeResources.length}
        />
      ) : (
        <EmptyStateCard>{translations.NO_ACTIVE_RESOURCES}</EmptyStateCard>
      )}
      {openDeleteConfirmationModal && (
        <ConfirmDeleteModal
          state={[openDeleteConfirmationModal, setOpenDeleteConfirmationModal]}
          title={`${uiTranslations.DELETE} ${activeResourceToDelete?.res_id}`}
          showWarningText
          deleteConfirmedCallback={handleConfirmDeleteActiveResource}
          customContentComponent={renderDeleteActiveResourceModalContent()}
        />
      )}
    </>
  );
};
export default ResourceDefinitionUsage;
