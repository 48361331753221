import {
  Accordion,
  EmptyStateCard,
  ErrorWarning,
  ExpandableCard,
  Icon,
  Spinner,
  WalCard,
} from '@humanitec/ui-components';
import { AxiosError } from 'axios';
import React, { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router';
import styled from 'styled-components';

import SectionHeader from '@src/components/shared/SectionHeader';
import ResourceDependencyGraph from '@src/containers/Orgs/Apps/containers/App/containers/DeploymentAndDeltaCommon/components/ViewDeploymentOrDeltaTabs/components/ResourceDependencyGraph/ResourceDependencyGraph';
import ResourcesTable from '@src/containers/Orgs/Resources/components/ResourcesTable/ResourcesTable';
import useResourceDefinitionsQuery from '@src/hooks/react-query/resources/queries/useResourceDefinitionsQuery';
import {
  TestConnectivity,
  TestConnectivityConditionStatus,
} from '@src/hooks/react-query/runtime-actions/useCheckConnectivityQuery';
import i18n from '@src/i18n/i18n';
import { ApiErrorResponse } from '@src/models/ApiErrorResponse';

const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const translations = i18n.t('COMPONENTS').TEST_CLUSTER_MODAL.TEST_RESULTS;

const mapStatusToReadable = (type: string): { title: string; description?: ReactNode } => {
  switch (type) {
    case 'GraphResolvable':
      return {
        title: translations.GRAPH_RESOLVABLE_TITLE,
        description: (
          <Trans defaults={translations.GRAPH_RESOLVABLE_DESCRIPTION}>
            <Link
              target={'_blank'}
              className={'txt-base'}
              rel={'noopener noreferrer'}
              to={'https://developer.humanitec.com/platform-orchestrator/resources/resource-graph/'}
            />
          </Trans>
        ),
      };
    case 'GraphCanProvision':
      return {
        title: translations.GRAPH_CAN_PROVISION_TITLE,
        description: translations.GRAPH_CAN_PROVISION_DESCRIPTION,
      };
    case 'RepositoryCanConnect':
      return {
        title: translations.REPO_CAN_CONNECT_TITLE,
        description: translations.REPO_CAN_CONNECT_DESCRIPTION,
      };
    case 'ExpectedDefinitionsMatching':
      return {
        title: translations.EXPECTED_DEFINITIONS_MATCHING_TITLE,
        description: translations.EXPECTED_DEFINITIONS_MATCHING_DESCRIPTION,
      };
    case 'AgentCanConnect':
      return {
        title: translations.AGENT_CONNECTED_TITLE,
        description: (
          <Trans defaults={translations.AGENT_CONNECTED_DESCRIPTION}>
            <Link
              target={'_blank'}
              className={'txt-base'}
              rel={'noopener noreferrer'}
              to={
                'https://developer.humanitec.com/integration-and-extensions/humanitec-agent/overview/'
              }
            />
          </Trans>
        ),
      };
    case 'ClusterCanConnect':
      return {
        title: translations.CLUSTER_CAN_CONNECT_TITLE,
        description: translations.CLUSTER_CAN_CONNECT_DESCRIPTION,
      };
    case 'Authorized':
      return {
        title: translations.AUTHORIZED_TITLE,
        description: translations.AUTHORIZED_DESCRIPTION,
      };
    case 'OperatorModeEnabled':
      return {
        title: translations.OPERATOR_MODE_ENABLED_TITLE,
        description: translations.OPERATOR_MODE_ENABLED_DESCRIPTION,
      };
    case 'OperatorInstalled':
      return {
        title: translations.OPERATOR_INSTALLED_TITLE,
        description: (
          <Trans defaults={translations.OPERATOR_INSTALLED_DESCRIPTION}>
            <Link
              target={'_blank'}
              className={'txt-base'}
              rel={'noopener noreferrer'}
              to={
                'https://developer.humanitec.com/integration-and-extensions/humanitec-operator/overview/'
              }
            />
          </Trans>
        ),
      };
    default:
      return {
        title: type,
      };
  }
};

export const ClusterTestSection = ({
  header,
  value,
  className,
}: {
  header: string;
  value: ReactNode;
  className?: string;
}) => (
  <div className={className}>
    <SectionHeader backgroundColor={'transparent'} sticky={false}>
      {header}
    </SectionHeader>
    <span className={'txt-base'}>{value}</span>
  </div>
);

const ConditionStatus = ({ status }: { status: TestConnectivityConditionStatus }) => {
  return (
    <div className={'flex'}>
      <Icon
        overrideColor={status === 'True' ? 'green' : status === 'False' ? 'alert' : 'text'}
        name={status === 'True' ? 'checkmark' : status === 'False' ? 'cross' : 'question'}
        marginRight={'md'}
      />
      {status === 'True' ? 'Pass' : status === 'False' ? 'Fail' : 'Unknown'}
    </div>
  );
};

interface TestClusterContentProps {
  checkConnectivityMutationIsSuccess: boolean;
  checkConnectivityMutationIsError: boolean;
  checkConnectivityMutationData: TestConnectivity | undefined;
  checkConnectivityMutationIsPending: boolean;
  checkConnectivityMutationError: AxiosError<ApiErrorResponse> | null;
}
const TestClusterSteps = ({
  checkConnectivityMutationIsSuccess,
  checkConnectivityMutationIsError,
  checkConnectivityMutationData,
  checkConnectivityMutationIsPending,
  checkConnectivityMutationError,
}: TestClusterContentProps) => {
  // i18n
  const { t } = useTranslation();
  const modalTranslations = t('COMPONENTS').TEST_CLUSTER_MODAL;

  // React Query
  const { data: resourceDefinitions } = useResourceDefinitionsQuery();

  const hasResponse = checkConnectivityMutationIsError || checkConnectivityMutationIsSuccess;

  return (
    <>
      {checkConnectivityMutationIsPending ? (
        <EmptyStateCard>
          <div className={'flex'}>
            <Spinner size={'small'} className={'mr-sm'} />
            {modalTranslations.TESTING_CONNECTION}
          </div>
        </EmptyStateCard>
      ) : hasResponse && checkConnectivityMutationData ? (
        <>
          <Accordion
            items={checkConnectivityMutationData.conditions.map((condition) => ({
              headerContent: (
                <AccordionHeader>
                  {mapStatusToReadable(condition.type).title}
                  <ConditionStatus status={condition.status} />
                </AccordionHeader>
              ),
              id: condition.status,
              content: (
                <div>
                  {mapStatusToReadable(condition.type).description && (
                    <ClusterTestSection
                      header={'Description'}
                      value={mapStatusToReadable(condition.type).description}
                      className={'mb-md'}
                    />
                  )}
                  <ClusterTestSection
                    header={'Status'}
                    value={<ConditionStatus status={condition.status} />}
                    className={'mb-md'}
                  />
                  <SectionHeader backgroundColor={'transparent'} sticky={false}>
                    {modalTranslations.DETAILS}
                  </SectionHeader>
                  <WalCard padding={'small'} cardStyle={'transparent'}>
                    {condition.message}
                  </WalCard>
                </div>
              ),
              cardStyle: 'default',
            }))}
          />
          <ExpandableCard
            id={'card'}
            className={'my-md'}
            cardStyle={'transparent'}
            headerContent={modalTranslations.CLUSTER_GRAPH}
            content={
              <>
                <div className={'mb-md txt-sm'}>{modalTranslations.CLUSTER_GRAPH_DESCRIPTION}</div>
                <ResourceDependencyGraph
                  customResourceDependencyGraph={{
                    nodes: checkConnectivityMutationData.resource_summaries.map((node) => ({
                      ...node,
                      guresid: (node as any)?.gu_res_id,
                    })),
                  }}
                />
              </>
            }
          />
          <ExpandableCard
            id={'card'}
            className={'my-md'}
            cardStyle={'transparent'}
            headerContent={modalTranslations.CLUSTER_DEFINITIONS_USED}
            content={
              <>
                <ResourcesTable
                  hideFilters
                  readonly
                  resourceDefinitions={
                    resourceDefinitions?.filter((res) => {
                      const resSummaryIds = checkConnectivityMutationData.resource_summaries.map(
                        (summmary) => summmary.def_id
                      );
                      return resSummaryIds.includes(res.id);
                    }) ?? []
                  }
                />
              </>
            }
          />
        </>
      ) : (
        checkConnectivityMutationError && (
          <ErrorWarning
            code={checkConnectivityMutationError.response?.data.error}
            message={checkConnectivityMutationError.response?.data.message}
          />
        )
      )}
    </>
  );
};

export default TestClusterSteps;
